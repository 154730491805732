<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <h1 class="font-weight-light mb-0">
        API Documentation
      </h1>
      <span class="overline">A step by step api implementation.</span>

      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12" lg="4">
              <v-card class="mb-4 pa-5">
                <v-list class="" dense nav>
                  <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    :to="item.href"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="12" lg="8">
              <v-container>
                <router-view> </router-view>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  template: "#documentation",

  data: () => ({
    selectedLanguage: {},
    selectedResponse: {},
    requestHeaders: [
      {
        id: 2,
        label: "destination",
        dataType: "Array",
        description:
          "Header containing Latitude and Longitude (Eg. '[0.315133, 32.576353]') of destination point/position.",
      },
      {
        id: 3,
        label: "email",
        dataType: "String",
        description: "The Email of a company user who is accessing the API",
      },
      {
        id: 4,
        label: "publicKey",
        dataType: "String",
        description:
          "Basic authentication header containing the Public Key obtained from the dashboard credentials.",
      },
      {
        id: 5,
        label: "privateKey",
        dataType: "String",
        description:
          "Basic authentication header containing the Private Key obtained from the dashboard credentials.",
      },
      {
        id: 8,
        label: "action",
        dataType: "String",
        description:
          "The header carying an instruction of the request (Eg. 'estimateDeliveryFeesAndTime, nearestDriverDistance, directRequestDelivery, approvalRequestDelivery')",
      },
    ],
    items: [
      {
        title: "Direct Request Delivery",
        icon: "mdi-cogs",
        href: "directRequestDelivery",
      },
      {
        title: "Estimated Delivery Price",
        icon: "mdi-cogs",
        href: "estimatedDeliveryPrice",
      },
      {
        title: "Nearest Driver Distance",
        icon: "mdi-cogs",
        href: "nearestDriverDistance",
      },
      {
        title: "Request Status Call",
        icon: "mdi-cogs",
        href: "requestStatus",
      },
      {
        title: "Driver Location Call",
        icon: "mdi-cogs",
        href: "driverLocation",
      },
    ],

    phpSample: `
    <?php
      require_once 'HTTP/Request2.php';

      $request = new Http_Request2('https://logistic.groupngs.com/api/');
      $url = $request->getUrl();

      $headers = array(
          // Request headers
          'Authorization' => '',
          'X-Target-Environment' => '',
          'X-Callback-Url' => '',
          'Content-Type' => 'application/json',
      );

      $request->setHeader($headers);

      $parameters = array(
          // Request parameters
      );

      $url->setQueryVariables($parameters);

      $request->setMethod(HTTP_Request2::METHOD_POST);

      // Request body
      $request->setBody("{body}");

      try
      {
          $response = $request->send();
          echo $response->getBody();
      }
      catch (HttpException $ex)
      {
          echo $ex;
      }

      ?>`,

    javaSample: `
    // // This sample uses the Apache HTTP client from HTTP Components (http://hc.apache.org/httpcomponents-client-ga/)
    import java.net.URI;
    import org.apache.http.HttpEntity;
    import org.apache.http.HttpResponse;
    import org.apache.http.client.HttpClient;
    import org.apache.http.client.methods.HttpGet;
    import org.apache.http.client.utils.URIBuilder;
    import org.apache.http.impl.client.HttpClients;
    import org.apache.http.util.EntityUtils;

    public class JavaSample 
    {
        public static void main(String[] args) 
        {
            HttpClient httpclient = HttpClients.createDefault();

            try
            {
                URIBuilder builder = new URIBuilder("https://logistic.groupngs.com/api/");


                URI uri = builder.build();
                HttpPost request = new HttpPost(uri);
                request.setHeader("Authorization", "");
                request.setHeader("X-Target-Environment", "");
                request.setHeader("X-Callback-Url", "");
                request.setHeader("Content-Type", "application/json");


                // Request body
                StringEntity reqEntity = new StringEntity("{body}");
                request.setEntity(reqEntity);

                HttpResponse response = httpclient.execute(request);
                HttpEntity entity = response.getEntity();

                if (entity != null) 
                {
                    System.out.println(EntityUtils.toString(entity));
                }
            }
            catch (Exception e)
            {
                System.out.println(e.getMessage());
            }
        }
    }
`,

    pythonSample: `
    ########### Python 2.7 #############
    import httplib, urllib, base64

    headers = {
        # Request headers
        'Authorization': '',
        'X-Target-Environment': '',
        'X-Callback-Url': '',
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': '{subscription key}',
    }

    params = urllib.urlencode({
    })

    try:
        conn = httplib.HTTPSConnection('https://logistic.groupngs.com/api/')
        conn.request("POST", params, "{body}", headers)
        response = conn.getresponse()
        data = response.read()
        print(data)
        conn.close()
    except Exception as e:
        print("[Errno {0}] {1}".format(e.errno, e.strerror))

    ####################################

    ########### Python 3.2 #############
    import http.client, urllib.request, urllib.parse, urllib.error, base64

    headers = {
        # Request headers
        'Authorization': '',
        'X-Target-Environment': '',
        'X-Callback-Url': '',
        'Content-Type': 'application/json',
    }

    params = urllib.parse.urlencode({
    })

    try:
        conn = http.client.HTTPSConnection('https://logistic.groupngs.com/api/')
        conn.request("POST", params, "{body}", headers)
        response = conn.getresponse()
        data = response.read()
        print(data)
        conn.close()
    except Exception as e:
        print("[Errno {0}] {1}".format(e.errno, e.strerror))

    ####################################`,

    javaScriptSample: `
        $(function() {
            var params = {
                // Request parameters
            };
          
            $.ajax({
                url: "https://logistic.groupngs.com/api/?" + $.param(params),
                beforeSend: function(xhrObj){
                    // Request headers
                    xhrObj.setRequestHeader("Authorization","");
                    xhrObj.setRequestHeader("X-Target-Environment","");
                    xhrObj.setRequestHeader("X-Callback-Url","");
                    xhrObj.setRequestHeader("Content-Type","application/json");
                    xhrObj.setRequestHeader("Ocp-Apim-Subscription-Key","{subscription key}");
                },
                type: "POST",
                // Request body
                data: "{body}",
            })
            .done(function(data) {
                alert("success");
            })
            .fail(function() {
                alert("error");
            });
        });`,

    programmingLanguages: [
      {
        text: "JavaScript",
        value: "nodejs",
        icon: "mdi mdi-language-javascript",
      },
      {
        text: "PHP",
        value: "php",
        icon: "mdi-language-php",
      },
      {
        text: "Java",
        value: "java",
        icon: "mdi-language-java",
      },
      {
        text: "Python",
        value: "python",
        icon: "mdi-language-python",
      },
    ],

    apiResponses: [
      {
        text: "Successful response",
        value: "success",
      },
      {
        text: "Failed response",
        value: "fail",
      },
    ],

    successfulResponse: `
    {
      "distance": "129332.5",
      "estimatedFee": "13000",
      "message": "OK",
      "status": "200",
    }`,

    failedResponse: `
    {
      "message": "",
      "statis": "500",
    }`,
  }),
};
</script>
